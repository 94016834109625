import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import * as React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Autocomplete, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid } from "@mui/x-data-grid";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import routes from "routes";
import { axios_get, axios_post, axios_post_image } from '../../../axios';
import { ToastMassage } from '../../../toast';
import moment from "moment";

function Edit_Item() {
  const navigate = useNavigate();
  const params = useParams();
  const [formError, setFormError] = useState({});
  const [autocompleteuomValue, setAutocompleteuomValue] = useState("");
  const [autocompleteItemValue, setAutocompleteItemValue] = useState("");
  const [autocompletecolorValue, setautocompleteColorValue] = useState("");
  const [autocompletesizeValue, setautocompleteSizeValue] = useState("");
  const [autocompletedepartValue, setautocompleteDepartValue] = useState("");
  const [autocompletedfamilyValue, setautocompleteFamilyValue] = useState("");
  const [autocompletedSubfamilyValue, setautocompletedSubfamilyValue] = useState("");
  const [autocompletedBrandValue, setautocompletedBrandValue] = useState("");
  const [autocompletedSupplierValue, setautocompletedSupplierValue] = useState("");
  const [autocompletedTax1Value, setautocompleteTax1Value] = useState("");
  const [autocompletedTax2Value, setautocompleteTax2Value] = useState("");
  const [autocompletedTax3Value, setautocompleteTax3Value] = useState("");

  const [uoms, setuomList] = useState([]);
  const [isSubmit, setisSubmit] = useState(false);
  const [itemCategories, setItemCategories] = useState([]);
  const [itemcolor, setItemColor] = useState([]);
  const [itemsize, setItemSize] = useState([]);
  const [itemdepat, setItemDepart] = useState([]);
  const [itemfamily, setItemFamliy] = useState([]);
  const [itemSubfamily, setItemSubFamliy] = useState([]);
  const [itemBrand, setItemBrand] = useState([]);
  const [itemSupplier, setItemSupplier] = useState([]);
  const [Tax1, setTax1] = useState([]);
  let user_data = JSON.parse(localStorage.getItem("user_data"));
  const [formData, setFormData] = useState({
    itemcatname: "",
    barcode: "",
    itemdesc: "",
    itemdesclong: "",
    itemdesc3: "",
    itemdesc4: "",
    itemupc: "",
    itemref: "",
    stylecode: "",
    colorname: "",
    sizename: "",
    departname: "",
    familyname: "",
    subfamliy: "",
    brandname: "",
    hsncode: "",
    itemcost: "",
    itemprice: "",
    itemlanprice: "",
    minstklvl: "",
    maxstklvl: "",
    itmstkmgmt: "",
    itmuom: "",
    itmwweight: "",
    itmwpurunit: "",
    itmwsalesunit: "",
    itmtax1code: "",
    itmtax2code: "",
    itmtax3code: "",
    itmcostingmet: "",
    suppliername: "",
    itmexpiry: "",
    note1: "",
    note2: "",
    note3: "",
    itmdt1: "",
    itmdt2: "",
    status: "1",
    rate: "",
    uom: "",
    price: "",
    stock: "",
    code: "",
    barcode: "",
    partNumber: "",
    name: "",
    tax: "",
    addedby: `${user_data.firstname} ${user_data.lastname}`,
    createddt: new Date().toLocaleString(),
    id: params.id
  });
  console.log("formData", formData);
  useEffect(() => {
    uomList();
    OrderNuberRange();
    fetchCategories();
    fetchColors();
    fetchSize();
    fetchDepart();
    fetchFamily();
    fetchSubFamily();
    fetchBrand();
    fetchSupplier();
    fetchTax1();
  }, []);
  const fetchCategories = async () => {
    try {
      const response = await axios_post(true, "item_category/list");
      setItemCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchColors = async () => {
    try {
      const response = await axios_post(true, "item_color/list");
      setItemColor(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchSize = async () => {
    try {
      const response = await axios_post(true, "size_master/list");
      setItemSize(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchDepart = async () => {
    try {
      const response = await axios_post(true, "item_department/list");
      setItemDepart(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchFamily = async () => {
    try {
      const response = await axios_post(true, "family_master/list");
      setItemFamliy(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchSubFamily = async () => {
    try {
      const response = await axios_post(true, "sub_family_master/list");
      setItemSubFamliy(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchBrand = async () => {
    try {
      const response = await axios_post(true, "brand/list");
      setItemBrand(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchSupplier = async () => {
    try {
      const response = await axios_post(true, "supplier/list");
      setItemSupplier(response.data?.records);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchTax1 = async () => {
    try {
      const response = await axios_post(true, "tax_master/list");
      setTax1(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const uomList = async () => {
    const response = await axios_post(true, "item_uom/list");
    if (response) {
      console.log("response", response)

      if (response.status) {
        setuomList(response.data);
      } else {
        ToastMassage(response.message, 'error')
      }
    }
  }
  const handleAutocompleteChange = (event, newValue, type) => {
    if (type == 'uom') {
      setAutocompleteuomValue(newValue);
      setFormData((prevData) => ({
        ...prevData,
        "uom": newValue == null ? "" : newValue?.id,
      }));
    }

    if (type == "itemcatname") {
      setAutocompleteItemValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "itemcatname": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "colorname") {
      setautocompleteColorValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "colorname": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "sizename") {
      setautocompleteSizeValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "sizename": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "departname") {
      setautocompleteDepartValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "departname": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "familyname") {
      setautocompleteFamilyValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "familyname": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "subfamliy") {
      setautocompletedSubfamilyValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "subfamliy": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "brandname") {
      setautocompletedBrandValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "brandname": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "suppliername") {
      setautocompletedSupplierValue(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "suppliername": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "itmtax1code") {
      setautocompleteTax1Value(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "itmtax1code": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "itmtax2code") {
      setautocompleteTax2Value(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "itmtax2code": newValue == null ? "" : newValue?.id,
      }));
    }
    if (type == "itmtax3code") {
      setautocompleteTax3Value(newValue)
      setFormData((prevData) => ({
        ...prevData,
        "itmtax3code": newValue == null ? "" : newValue?.id,
      }));
    }
  };

  const OrderNuberRange = async () => {
    let params = {
      function_for: "item"
    }
    const response = await axios_post(true, "code_setting/get-next-comming-code", params);
    console.log("response", response)
    if (response) {
      if (response.status) {
        setFormData((prevData) => ({
          ...prevData,
          "code": response.data?.number_is,
        }));
      } else {
        ToastMassage(response.message, 'error')
      }
    }
  }

  const validation = (formData) => {
    let errors = {};


    if (!formData.createddt) errors.createddt = "Created Date is required";
    if (!formData.addedby) errors.addedby = "Added By is required";
    if (!formData.rate) errors.rate = "PTR is required";

    // if (!formData.code) {
    //   errors.code = "Code  is required";
    // }
    // if (!formData.tax) {
    //   errors.tax = "Tax  is required";
    // }
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.tax) {
      errors.tax = "Tax  is required";
    }
    if (!formData.stock) {
      errors.stock = "Stock  is required";
    }
    if (!formData.barcode) {
      errors.barcode = "Barcode is required";
    }
    if (!formData.partNumber) {
      errors.partNumber = "Part Number is required";
    }
    if (!formData.uom) {
      errors.uom = "Unit of measurement is required";
    }
    // if (!formData.itmstkmgmt) {
    //   errors.itmstkmgmt = "Stock management is required";
    // }
    // if (!formData.itemlanprice) {
    //   errors.itemlanprice = "Landed cost is required";
    // }
    // if (!formData.itemprice) {
    //   errors.itemprice = "Price is required";
    // }
    // if (!formData.itmcostingmet) {
    //   errors.itmcostingmet = "Costing method is required";
    // }
    // if (!formData.brandname) {
    //   errors.brandname = "Brand is required";
    // }
    // if (!formData.subfamliy) {
    //   errors.subfamliy = "Sub family is required";
    // }
    // if (!formData.familyname) {
    //   errors.familyname = "Family is required";
    // }
    // if (!formData.departname) {
    //   errors.departname = "Department is required";
    // }
    // if (!formData.sizename) {
    //   errors.sizename = "Size is required";
    // }
    // if (!formData.colorname) {
    //   errors.colorname = "Color is required";
    // }
    // if (!formData.stylecode) {
    //   errors.stylecode = "Style codeis required";
    // }
    // if (!formData.itemref) {
    //   errors.itemref = "Item reference is required";
    // }
    // if (!formData.itemupc) {
    //   errors.itemupc = "UPC is required";
    // }
    // if (!formData.itemdesc4) {
    //   errors.itemdesc4 = "Description 4 is required";
    // }
    // if (!formData.itemdesc3) {
    //   errors.itemdesc3 = "Description 3 is required";
    // }
    // if (!formData.itemdesclong) {
    //   errors.itemdesclong = "Long description is required";
    // }
    // if (!formData.itemdesc) {
    //   errors.itemdesc = "Description is required";
    // }
    // if (!formData.itemcatname) {
    //   errors.itemcatname = "Item category is required";
    // }
    // if (!formData.itmtax1code) {
    //   errors.itmtax1code = "Tax 1 is required";
    // }
    // if (!formData.itmcostingmet) {
    //   errors.itmcostingmet = "Costing method is required";
    // }

    // if (!formData.status) {
    //   errors.status = "status  is required";
    // }

    return errors;
  };


  const fetchOrderDetails = async () => {
    try {
      const response = await axios_post(true, 'item/details', {
        id: params.id
      });
      if (response.status) {
        const orderData = response.data;
        setFormData({
          ...formData,
          sno: "",
          barcode: orderData?.barcode,
          rate: orderData?.rate,
          price: orderData?.price,
          stock: orderData?.stock,
          partNumber: orderData?.partNumber,
          name: orderData?.name,
          tax: orderData?.tax,
          code: orderData?.code,
          itemcatname: orderData?.itemcatname,
          colorname: orderData?.colorname,
          itemdesc: orderData?.itemdesc,
          itemdesclong: orderData?.itemdesclong,
          itemdesc3: orderData?.itemdesc3,
          itemdesc4: orderData?.itemdesc4,
          itemupc: orderData?.itemupc,
          itemref: orderData?.itemref,
          stylecode: orderData?.stylecode,
          sizename: orderData?.sizename,
          departname: orderData?.departname,
          familyname: orderData?.familyname,
          subfamliy: orderData?.subfamliy,
          brandname: orderData?.brandname,
          hsncode: orderData?.hsncode,
          itemcost: orderData?.itemcost,
          itemprice: orderData?.itemprice,
          itemlanprice: orderData?.itemlanprice,
          minstklvl: orderData?.minstklvl,
          maxstklvl: orderData?.maxstklvl,
          itmstkmgmt: orderData?.itmstkmgmt,
          uom: orderData?.uom,
          itmwweight: orderData?.itmwweight,
          itmwpurunit: orderData?.itmwpurunit,
          itmwsalesunit: orderData?.itmwsalesunit,
          itmtax1code: orderData?.itmtax1code,
          itmtax2code: orderData?.itmtax2code,
          itmtax3code: orderData?.itmtax3code,
          itmcostingmet: orderData?.itmcostingmet,
          suppliername: orderData?.suppliername,
          itmexpiry: orderData?.itmexpiry,
          note1: orderData?.note1,
          note2: orderData?.note2,
          note3: orderData?.note3,
          itmdt1: orderData?.itmdt1,
          itmdt2: orderData?.itmdt2,

          code: orderData.item_code,
          name: orderData.item_name,
          stock: orderData.stock,
          partNumber: orderData.partNumber,
          tax: orderData.item_tax,
          price: orderData.item_vat_percentage,
          uom: orderData?.item_main_prices?.[0]?.item_uom?.id,
          item_uom: orderData?.item_main_prices?.[0]?.item_uom?.name,

        });
        setAutocompleteuomValue(orderData?.item_main_prices?.[0]?.item_uom)
        let itemcategoryfetch = {
          "itemcatname": orderData.itemcategory?.name,
          "id": orderData.itemcatname,
        }
        setAutocompleteItemValue(itemcategoryfetch);

        let itemcolorfetch = {
          "itemcolname": orderData.item_color?.itemcolname,
          "id": orderData.colorname,
        }
        setautocompleteColorValue(itemcolorfetch);

        let itemsizefetch = {
          "itemsizename": orderData?.size_master?.itemsizename,
          "id": orderData.sizename,
        }
        setautocompleteSizeValue(itemsizefetch);

        let itemdepartfetch = {
          "itemdeptname": orderData?.item_department?.itemdeptname,
          "id": orderData.departname,
        }
        setautocompleteDepartValue(itemdepartfetch);

        let itemfamilyfetch = {
          "itemfamname": orderData?.family_master?.itemfamname,
          "id": orderData.familyname,
        }
        setautocompleteFamilyValue(itemfamilyfetch);

        let itemsubfamilyfetch = {
          "itemsfamname": orderData?.sub_family_master?.itemsfamname,
          "id": orderData.subfamliy,
        }
        setautocompletedSubfamilyValue(itemsubfamilyfetch);

        let itembrandfetch = {
          "brandname": orderData?.brand?.brandname,
          "id": orderData.brandname,
        }
        setautocompletedBrandValue(itembrandfetch);

        let itemtax1fetch = {
          "taxpor1": orderData?.tax_master_1?.taxpor1,
          "id": orderData.itmtax1code,
        }
        setautocompleteTax1Value(itemtax1fetch);

        let itemtax2fetch = {
          "taxpor2": orderData?.tax_master_2?.taxpor2,
          "id": orderData.itmtax2code,
        }
        setautocompleteTax2Value(itemtax2fetch);

        let itemtax3fetch = {
          "taxpor3": orderData?.tax_master_3?.taxpor3,
          "id": orderData.itmtax3code,
        }
        setautocompleteTax3Value(itemtax3fetch);

        let itemsupplierfetch = {
          "users": {
            "firstname": orderData?.customer_info?.users?.firstname,
            "id": orderData.suppliername,
          }
        }
        setautocompletedSupplierValue(itemsupplierfetch);
      } else {
        ToastMassage(response.message, 'error');
      }
    } catch (error) {
      console.error("Failed to fetch order details:", error);
    }
  };
  useEffect(() => {
    fetchOrderDetails();
  }, []);
  const handleSubmit = async (event) => {
    setisSubmit(true);
    event.preventDefault();
    let errors = validation(formData);
    console.log("errors", errors)

    if (Object.keys(errors).length > 0) {
      setisSubmit(false);
      setFormError(errors);
    } else {
      setFormError({});
      const response = await axios_post(true, "item/update", formData);
      if (response) {
        setisSubmit(false);
        if (response.status) {
          ToastMassage(response.message, 'success')
          navigate("/item");

        } else {
          ToastMassage(response.message, 'error')
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate("/item");
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12}>
            <form onSubmit={handleSubmit} method="POST" action="#">
              <Card >
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Grid container spacing={0}>
                    <Grid item xs={2} mr={35}>
                      <MDTypography variant="h6" color="white">
                        <Icon fontSize="small">shopping_cart</Icon>
                        Edit Item
                      </MDTypography>
                    </Grid>

                    <Grid item xs={2} ml={40}>
                      <MDTypography component={Link} to="/item">
                        <MDButton variant="gradient" color="light">
                          Back
                        </MDButton>
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>

                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Code</InputLabel>
                          <MDInput
                            type="text"
                            // label="Order Number"
                            variant="outlined"
                            name="code"
                            value={formData.code}
                            onChange={handleChange}
                            sx={{ width: 300 }}
                            className="small-input"
                            disabled
                          />
                          {formError.code && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.code}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Name</InputLabel>
                          <MDInput
                            type="text"
                            // label="Order Number"
                            variant="outlined"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="small-input"
                          />
                          {formError.name && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.name}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Tax%</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            sx={{ width: 300 }}
                            name="tax"
                            value={formData.tax}
                            onChange={handleChange}
                            className="small-input"
                          />
                          {formError.tax && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.tax}</MDTypography>}

                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Stock</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            className="small-input"
                            name="stock"
                            value={formData.stock}
                            onChange={handleChange}
                          />
                          {formError.stock && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.stock}</MDTypography>}

                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Price</InputLabel>
                          <MDInput
                            type="number"
                            name="price"
                            variant="outlined"
                            className="small-input"
                            value={formData.price}
                            onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemprice}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Barcode</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            className="small-input"
                            name="barcode"
                            value={formData.barcode}
                            onChange={handleChange}
                          />
                          {formError.barcode && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.barcode}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Part No.</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            className="small-input"
                            name="partNumber"
                            value={formData.partNumber}
                            onChange={handleChange}
                          />
                          {formError.partNumber && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.partNumber}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>PTR</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            className="small-input"
                            name="rate"
                            value={formData.rate}
                            onChange={handleChange}
                          />
                          {formError.rate && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.rate}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <InputLabel sx={{ mb: 1 }}>Unit of Measurement </InputLabel>
                        <Autocomplete
                          disablePortal
                          id="uom-combo-box"
                          options={uoms}
                          getOptionLabel={(option) => option.name || ''}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.name}</li>
                          )}
                          value={autocompleteuomValue}
                          onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'uom')}
                          className="small-autocomplete"
                          renderInput={(params) => <TextField {...params} className="small-input" />}
                        />
                        {formError.uom && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.uom}</MDTypography>}
                      </Grid>
                      {/* <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Item Category</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemCategories}
                            getOptionLabel={(option) => option.itemcatname || ''}

                            renderOption={(props, option) => (
                              <li {...props}>{option?.itemcatname}</li>
                            )}
                            value={autocompleteItemValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'itemcatname')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}

                          />
                          {formError.itemcatname && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemcatname}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Description </InputLabel>
                          <MDInput
                            type="varchar"
                            name="itemdesc"
                            variant="outlined"
                            className="small-input"
                            value={formData.itemdesc}
                            onChange={(e) => setFormData({ ...formData, itemdesc: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemdesc}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Long Description</InputLabel>
                          <MDInput
                            type="varchar"
                            name="itemdesclong"
                            variant="outlined"
                            className="small-input"
                            value={formData.itemdesclong}
                            onChange={(e) => setFormData({ ...formData, itemdesclong: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemdesclong}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Description 3</InputLabel>
                          <MDInput
                            type="varchar"
                            name="itemdesc3"
                            variant="outlined"
                            className="small-input"
                            value={formData.itemdesc3}
                            onChange={(e) => setFormData({ ...formData, itemdesc3: e.target.value })}
                          />
                          {formError.itemdesc3 && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemdesc3}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Description 4</InputLabel>
                          <MDInput
                            type="varchar"
                            name="itemdesc4 4"
                            variant="outlined"
                            className="small-input"
                            value={formData.itemdesc4}
                            onChange={(e) => setFormData({ ...formData, itemdesc4: e.target.value })}
                          />
                          {formError.itemdesc4 && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemdesc4}</MDTypography>
                          )}
                        </MDBox>
                      </Grid> */}

                      {/* <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>UPC</InputLabel>
                          <MDInput
                            type="varchar"
                            name="itemupc"
                            variant="outlined"
                            className="small-input"
                            value={formData.itemupc}
                            onChange={(e) => setFormData({ ...formData, itemupc: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemupc}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Item Reference</InputLabel>
                          <MDInput
                            type="varchar"
                            name="itemref"
                            variant="outlined"
                            className="small-input"
                            value={formData.itemref}
                            onChange={(e) => setFormData({ ...formData, itemref: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemref}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Style Code</InputLabel>
                          <MDInput
                            type="varchar"
                            name="stylecode"
                            variant="outlined"
                            className="small-input"
                            value={formData.stylecode}
                            onChange={(e) => setFormData({ ...formData, stylecode: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.stylecode}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Color</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemcolor}
                            getOptionLabel={(option) => option.itemcolname || ''}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.itemcolname}</li>
                            )}
                            value={autocompletecolorValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'colorname')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                          />
                          {formError.colorname && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.colorname}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Size</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemsize}
                            getOptionLabel={(option) => option.itemsizename || ''}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.itemsizename}</li>
                            )}
                            value={autocompletesizeValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'sizename')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                          />
                          {formError.sizename && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.sizename}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Department</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemdepat}
                            getOptionLabel={(option) => option.itemdeptname || ''}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.itemdeptname}</li>
                            )}
                            value={autocompletedepartValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'departname')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                          />
                          {formError.departname && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.departname}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Family</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemfamily}
                            getOptionLabel={(option) => option.itemfamname || ''}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.itemfamname}</li>
                            )}
                            value={autocompletedfamilyValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'familyname')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                          />
                          {formError.familyname && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.familyname}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Sub Family</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemSubfamily}
                            getOptionLabel={(option) => option.itemsfamname || ''}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.itemsfamname}</li>
                            )}
                            value={autocompletedSubfamilyValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'subfamliy')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                          />
                          {formError.subfamliy && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.subfamliy}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Brand </InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemBrand}
                            getOptionLabel={(option) => option.brandname || ''}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.brandname}</li>
                            )}
                            value={autocompletedBrandValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'brandname')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                          />
                          {formError.brandname && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.brandname}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>HSN Code</InputLabel>
                          <MDInput
                            type="varchar"
                            name="hsncode"
                            variant="outlined"
                            className="small-input"
                            value={formData.hsncode}
                            onChange={(e) => setFormData({ ...formData, hsncode: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.hsncode}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Price</InputLabel>
                          <MDInput
                            type="number"
                            name="itemprice"
                            variant="outlined"
                            className="small-input"
                            value={formData.itemprice}
                            onChange={(e) => setFormData({ ...formData, itemprice: e.target.value })}
                          />
                          {formError.price && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemprice}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Landed Cost</InputLabel>
                          <MDInput
                            type="numeric"
                            variant="outlined"
                            className="small-input"
                            name="itemlanprice"
                            value={formData.itemlanprice}
                            onChange={handleChange}
                          />
                          {formError.itemlanprice && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemlanprice}</MDTypography>}

                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Min Stock Level</InputLabel>
                          <MDInput
                            type="Int"
                            variant="outlined"
                            className="small-input"
                            name="minstklvl"
                            value={formData.minstklvl}
                            onChange={handleChange}
                          />
                          {formError.minstklvl && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.minstklvl}</MDTypography>}

                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Max stock level</InputLabel>
                          <MDInput
                            type="Int"
                            variant="outlined"
                            className="small-input"
                            name="maxstklvl"
                            value={formData.maxstklvl}
                            onChange={handleChange}
                          />
                          {formError.maxstklvl && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.maxstklvl}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Stock Managemente</InputLabel>
                          <Autocomplete
                            options={["Managed", " Not Managed"]}
                            value={formData.itmstkmgmt}
                            onChange={(event, newValue) => setFormData({ ...formData, itmstkmgmt: newValue })}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                            // sx={{ width: 300 }}
                            className="small-autocomplete"
                          />
                          {formError.itmstkmgmt && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmstkmgmt}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <InputLabel sx={{ mb: 1 }}>Unit of Measurement </InputLabel>
                        <Autocomplete
                          disablePortal
                          id="uom-combo-box"
                          options={uoms}
                          getOptionLabel={(option) => option.name || ''}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.name}</li>
                          )}
                          value={autocompleteuomValue}
                          onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'uom')}
                          className="small-autocomplete"
                          renderInput={(params) => <TextField {...params} className="small-input" />}
                        />
                        {formError.uom && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.uom}</MDTypography>}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Weight</InputLabel>
                          <MDInput
                            type="numeric"
                            variant="outlined"
                            className="small-input"
                            name="itmwweight"
                            value={formData.itmwweight}
                            onChange={handleChange}
                          />
                          {formError.itmwweight && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmwweight}</MDTypography>}

                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Purchase Unit</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            className="small-input"
                            name="itmwpurunit"
                            value={formData.itmwpurunit}
                            onChange={handleChange}
                          />
                          {formError.itmwpurunit && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmwpurunit}</MDTypography>}

                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Sales Unit</InputLabel>
                          <MDInput
                            type="numeric"
                            variant="outlined"
                            className="small-input"
                            name="itmwsalesunit"
                            value={formData.itmwsalesunit}
                            onChange={handleChange}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Tax 1</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            options={Tax1}
                            getOptionLabel={(option) => option?.taxpor1 || ""}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.taxpor1} - {option?.taxpor1desc}</li>
                            )}
                            value={autocompletedTax1Value}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'itmtax1code')}
                            className="small-autocomplete"
                            renderInput={(params) => <TextField {...params} className="small-input" />}
                          />
                          {formError.itmtax1code && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmtax1code}</MDTypography>}
                        </MDBox>
                      </Grid> */}

                      {/* <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Tax 2</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            options={Tax1}
                            getOptionLabel={(option) => option?.taxpor2 || ""}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.taxpor2} - {option?.taxpor2desc}</li>
                            )}
                            value={autocompletedTax2Value}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'itmtax2code')}
                            className="small-autocomplete"
                            renderInput={(params) => <TextField {...params} className="small-input" />}
                          />
                          {formError.itmtax2code && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmtax2code}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Tax 3</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            options={Tax1}
                            getOptionLabel={(option) => option?.taxpor3 || ""}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.taxpor3} - {option?.taxpor3desc}</li>
                            )}
                            value={autocompletedTax3Value}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'itmtax3code')}
                            className="small-autocomplete"
                            renderInput={(params) => <TextField {...params} className="small-input" />}
                          />
                          {formError.itmtax3code && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmtax3code}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Costing method</InputLabel>
                          <Autocomplete
                            options={["FIFO - First in first out", "LIFO - last in first out", "Standard - standard", "Average - average", " "]}
                            value={formData.itmcostingmet}
                            onChange={(event, newValue) => setFormData({ ...formData, itmcostingmet: newValue })}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                            // sx={{ width: 300 }}
                            className="small-autocomplete"
                          />
                          {formError.itmcostingmet && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmcostingmet}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Supplier Name</InputLabel>
                          <Autocomplete
                            disablePortal
                            id="uom-combo-box"
                            className="small-autocomplete"
                            options={itemSupplier}
                            getOptionLabel={(option) => option.users?.firstname || ''}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.users?.firstname}</li>
                            )}
                            value={autocompletedSupplierValue}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'suppliername')}
                            renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                          />
                          {formError.suppliername && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.suppliername}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Expiry</InputLabel>
                          <MDInput
                            type="date"
                            variant="outlined"
                            name="itmexpiry"
                            onChange={handleChange}
                            value={formData.itmexpiry}
                            className="small-input"
                          // disabled
                          />
                          {formError.itmexpiry && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmexpiry}</MDTypography>}
                        </MDBox>
                      </Grid> */}
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Note1</InputLabel>
                          <MDInput
                            type="varchar"
                            name="note1"
                            variant="outlined"
                            className="small-input"
                            value={formData.note1}
                            onChange={(e) => setFormData({ ...formData, note1: e.target.value })}
                          />
                          {formError.note1 && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.note1}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Note2</InputLabel>
                          <MDInput
                            type="varchar"
                            name="note2"
                            variant="outlined"
                            className="small-input"
                            value={formData.note2}
                            onChange={(e) => setFormData({ ...formData, note2: e.target.value })}
                          />
                          {formError.note2 && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.note2}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Note3</InputLabel>
                          <MDInput
                            type="varchar"
                            name="note3"
                            variant="outlined"
                            className="small-input"
                            value={formData.note3}
                            onChange={(e) => setFormData({ ...formData, note3: e.target.value })}
                          />
                          {formError.note3 && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.note3}</MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}> Date 1</InputLabel>
                          <MDInput
                            type="date"
                            variant="outlined"
                            name="itmdt1"
                            value={formData.itmdt1}
                            onChange={handleChange}
                            className="small-input"
                          // disabled
                          />
                          {formError.itmdt1 && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmdt1}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}> Date 2</InputLabel>
                          <MDInput
                            type="date"
                            variant="outlined"
                            name="itmdt2"
                            value={formData.itmdt2}
                            onChange={handleChange}
                            className="small-input"
                          // disabled
                          />
                          {formError.itmdt2 && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itmdt2}</MDTypography>}
                        </MDBox>
                      </Grid>

                      {/* <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Barcode</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            className="small-input"
                            name="barcode"
                            value={formData.barcode}
                            onChange={handleChange}
                          />
                          {formError.barcode && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.barcode}</MDTypography>}
                        </MDBox>
                      </Grid> */}
                      {/* <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Cost</InputLabel>
                          <MDInput
                            type="numeric"
                            variant="outlined"
                            className="small-input"
                            name="itemcost"
                            value={formData.itemcost}
                            onChange={handleChange}
                          />
                          {formError.itemcost && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.itemcost}</MDTypography>}

                        </MDBox>
                      </Grid> */}
                      {/* <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Part No.</InputLabel>
                          <MDInput
                            type="number"
                            variant="outlined"
                            className="small-input"
                            name="partNumber"
                            value={formData.partNumber}
                            onChange={handleChange}
                          />
                          {formError.partNumber && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.partNumber}</MDTypography>}
                        </MDBox>
                      </Grid> */}
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Added By</InputLabel>
                          <MDInput
                            type="text"
                            variant="outlined"
                            name="addedby"
                            value={formData.addedby}
                            onChange={(e) => handleChange(e)}
                            className="small-input"
                            inputProps={{ maxLength: 40 }}
                            disabled
                          />
                          {formError.addedby && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.addedby}</MDTypography>}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Created Date</InputLabel>
                          <MDInput
                            type="text"
                            variant="outlined"
                            name="createddt"
                            value={formData.createddt}
                            className="small-input"
                            disabled
                          />
                          {formError.createddt && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.createddt}</MDTypography>}
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <MDBox pb={2}>
                          <InputLabel sx={{ mb: 1 }}>Status</InputLabel>
                          <Select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            sx={{ width: 250, height: 45 }}
                          >
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="0">Inactive</MenuItem>
                          </Select>
                          {formError.status && (
                            <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>
                              {formError.status}
                            </MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                      {/* <Grid item xs={12}> */}
                      <Grid container spacing={2} justifyContent="right" sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={2} ml={3}>
                          <MDBox sx={{ display: 'flex' }}>
                            <MDButton variant="gradient" disabled={isSubmit} color="info" type="submit" fullWidth>
                              {isSubmit ?
                                <CircularProgress color="white" size={24}
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                  }} />
                                : 'Save'
                              }
                            </MDButton>
                            <MDButton variant="gradient" disabled={isSubmit} color="secondary" type="submit" fullWidth sx={{ marginLeft: '15px' }} onClick={handleBack}>
                              cancel
                            </MDButton>
                          </MDBox>
                          <MDBox>

                          </MDBox>
                        </Grid>
                      </Grid>
                      {/* </Grid> */}
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit_Item;
